<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        title="즉시조치"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        @linkClick="immlinkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addImprImm" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        class="q-mt-md"
        title="개선요청"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="grid2.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        @linkClick="imprlinkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-check-impr-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyCheckId: '',
        safetyCheckResultId: '',
        key: '',
        checkStatusCd: '',
        sysApprovalRequestId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
        height: '350px'
      },
      grid2: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '요청내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
        height: '362px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      deleteUrl: '',
      searchUrl: '',
      isComplete: false,
      isSave: false,
      isSave2: false,
    };
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 260) + 'px';
    },
    disabled() {
     return this.popupParam.checkStatusCd === 'MCSC000015' || Boolean(this.popupParam.sysApprovalRequestId)
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.safetycheck.improve.get.url;

      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.safetyCheckResultId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.safetyCheckResultId, this.popupParam.key);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data =[]
          this.grid2.data=[]
          if (_result.data && _result.data.length > 0) {
            // 즉시조치
            this.grid.data = this.$_.filter(_result.data, { ibmClassCd: 'IC00000005' });
            // 개선요청
            this.grid2.data = this.$_.filter(_result.data, { ibmClassCd: 'IC00000001' });
          }
        },);
      } 
    },
    addImprImm() {
      this.popupOptions.title = '즉시개선'; // 즉시개선
      this.popupOptions.param = {
        requestContents: this.popupParam.checkItemName,
        relationTableKey: this.popupParam.safetyCheckResultId+"/"+this.popupParam.key,
        ibmTaskTypeCd: 'ITT0000075',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediate.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.iFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImmDetailPopup;
    },
    addImpr() {
      this.popupOptions.title = '개선 요청';
      this.popupOptions.param = {
        requestContents: this.popupParam.checkItemName,
        relationTableKey: this.popupParam.safetyCheckResultId+"/"+this.popupParam.key,
        ibmTaskTypeCd: 'ITT0000075',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.iFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    /* eslint-disable no-unused-vars */
    immlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '즉시개선 상세'; // 즉시개선 상세
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImmDetailPopup;
    },
    closeImmDetailPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    imprlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    closeImprDetailPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
  }
};
</script>
